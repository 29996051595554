import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation, PAGE_SIZE } from "../../constants/options";
import Breadcrumb from "../../components/shared-breadcrumb";
import VmModal from "../../components/shared-modal";
import { toJS } from "mobx";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED, INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const SkeletonLoading = () => {
  return <>
    <div className="text-center my-4"><Skeleton /></div>
    <div className="text-center my-4"><Skeleton /></div>
    <div className="text-center my-4"><Skeleton /></div>
  </>
}

const ExamQuestionsPage = observer(() => {
  const { rootStore, examStore } = useStores();
  const [showFullQuestionModal, setShowFullQuestionModal] = useState<boolean>(false);
  // Determine is update or create
  const [currentExamId, setCurrentExamId] = useState<number>(0);
  const [currentSettingId, setCurrentSettingId] = useState<number>(0);
  const [questionIdList, setQuestionIdList] = useState<any>([]);
  const [activeFunction, setActiveFunction] = useState<any>([]);

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.EXAM) == 0 ||
      examStore.examList.data.length != ApiDataResponseListLengthCheck(ApiDataListType.EXAM)) {
      examStore.getExamList(0, 0).then((response) => {
        onToggleExamSettingList(response.data[0].id);
      });
    }
  }, [])

  const onToggleExamSettingList = (id: number) => {
    examStore.getExamQuestionListByExamId(id)
      .then((res) => setCurrentSettingId(res.length > 0 ? res[0].settingId : 0));
    setCurrentExamId(id);
  }

  const handleCloseModal = () => {
    setShowFullQuestionModal(false);
    setQuestionIdList([]);
  }

  return (
    <ContentLayout
      pageName={t('EXAM_QUESTIONS') + t('MODULE_B')}
      pageHeading={t('EXAM_QUESTIONS')}
      breadCrumb={[
        { label: t('EXAM') + t('MODULE_B'), to: "" },
        { label: t('EXAM_QUESTIONS'), to: "/exam/exam-questions" }
      ]}
      showHeadingButton={false}
    >
      {/* Setting Section */}
      <div className="grid grid-cols-4 gap-4">
        <div className="max-h-96 overflow-auto">
          {
            examStore.examList.data.length == 0 && examStore.loading ? <SkeletonLoading /> :
              examStore.examList.data.map((exam: any, index: number) => (
                <Box key={`exam_select_${index}`}>
                  <button
                    type="button"
                    className={`${currentExamId == exam.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                    onClick={() => onToggleExamSettingList(exam.id)}
                  >
                    {exam.examCode}
                  </button>
                </Box>
              ))
          }
        </div>
        <div className="max-h-96 overflow-auto">
          {examStore.loading ? <SkeletonLoading /> :
            currentExamId != 0 && currentSettingId != 0 &&
            examStore.examSettingList.map((setting: any, index: number) => (
              <Box key={`setting_generated_select_${index}`}>
                <button
                  type="button"
                  className={`${currentSettingId == setting.settingId ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                  onClick={() => setCurrentSettingId(setting.settingId)}
                >
                  {t('SETTING_A')}{index + 1}: {t("DIFFICULTY")}{setting.difficulty}/{t("QUANTITY")}{setting.quantity}/{setting.markPerQuestion}{t('MARK_B')}
                </button>
              </Box>
            ))
          }
          {currentExamId == 0 || currentSettingId == 0 && <Typography textAlign={"center"} my={6}>{t('NO_SETTING_FOUND')}</Typography>}
        </div>

        <div className="col-span-2 max-h-96 overflow-auto">
          <VmButton
            className="bg-highlight text-white px-4 py-2 mb-4"
            style={{ height: 'fit-content' }}
            onClick={() => {
              setQuestionIdList(examStore.examSettingList.find(es => es.settingId == currentSettingId)
                .questionList.map(q => q.id))
              examStore.getFullQuestionListByExamId(currentExamId);
              setShowFullQuestionModal(true);
            }}
            disabled={currentExamId == 0 || currentSettingId == 0}
          >
            {t('EDIT_QUESTIONS')}
          </VmButton>
          <VmTable
            loading={examStore.loading}
            page={0}
            thead={["ID", t('QUESTION'), t('MARK'), t('TYPE')]}
            tbody={currentExamId != 0 && currentSettingId != 0 &&
              examStore.examSettingList.find(es => es.settingId == currentSettingId) &&
              examStore.examSettingList.find(es => es.settingId == currentSettingId)
                .questionList.map((question: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`question_list_${index}`}>
                      <td className={TD_FIRST}>{index + 1}</td>
                      <td className={TD_NORMAL}>{question.detail}</td>
                      <td className={TD_NORMAL}>{question.fullMark} {question.fullMark > 1 ? t('MARKS') : t('MARK_B')}</td>
                      <td className={TD_LAST}>{question.type}</td>
                    </tr>
                    <Box marginY={1} />
                  </>
                ))}
          />
        </div>
      </div>

      {/* Full Questions By Exam */}
      <VmModal
        width={400}
        openModal={showFullQuestionModal}
        onClose={handleCloseModal}
        title={`${t('QUESTION_REPO_FOR_EXAM')}${examStore.examList.length > 0 && currentExamId != 0 &&
          examStore.examList.find(e => e.id == currentExamId).examCode}`}
        buttonLabel={t('SAVE_CONFIRM')}
        disabledCondition={currentSettingId == 0 || examStore.examSettingList.length < 1 ||
          (questionIdList.length != examStore.examSettingList.find(es => es.settingId == currentSettingId).quantity)}
        buttonLabelWithoutConfirm={t('CHANGE_SAVE')}
        onClickConfirmedButton={() => {
          examStore.editExamQuestion([{ examSettingId: currentSettingId, questionIdList }])
            .then(() => {
              rootStore.notify(t('EXAM_QUESTIONS') + t('UPDATED_B'), 'success')
              examStore.getExamQuestionListByExamId(currentExamId);
            }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
          handleCloseModal();
        }}
      >
        {examStore.getAccessLoading || examStore.fullQuestionList.length < 1 ? <>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
        </>
          : <>
            <div className="border p-2 max-h-96 overflow-auto mt-2" style={{ marginTop: -10 }}>
              {examStore.fullQuestionList.find(q => q.settingId == currentSettingId) &&
                examStore.fullQuestionList.find(q => q.settingId == currentSettingId)
                  .questionList.map((question: any, index: number) => (
                    <button
                      type="button"
                      className={`hover:bg-highlight hover:text-white w-full text-left px-1 rounded-md text-sm
                        ${questionIdList.includes(question.id) && "bg-highlight text-white"}`}
                      key={`student_select_${index}`}
                      onClick={() => {
                        let tempIdList = questionIdList;
                        if (tempIdList.includes(question.id)) {
                          let i = tempIdList.indexOf(question.id);
                          tempIdList.splice(i, 1);
                        } else tempIdList.push(question.id);
                        setQuestionIdList(tempIdList);
                        setActiveFunction([]);
                      }}
                    >
                      {question.detail}
                    </button>
                  ))}
            </div>
            {currentSettingId > 0 && examStore.examSettingList.length > 0 &&
              (questionIdList.length != examStore.examSettingList.find(egq => egq.settingId == currentSettingId).quantity) &&
              <Typography variant={"caption"} marginTop={1}>
                {t('NUMBER_SELECTED_QUESTIONS_EQUAL_TO_QUANTITY_CHECK')}
                ({examStore.examSettingList
                  .find(es => es.settingId == currentSettingId).quantity}). {t('PLS_ADJUST_QUANTITY')}
              </Typography>
            }
            <Typography variant={"body2"} textAlign="center" marginTop={1}>
              <span className="text-red-500">{questionIdList.length}</span>
              <span className=""> / {currentSettingId != 0 && examStore.fullQuestionList.length > 0 &&
                examStore.fullQuestionList.find(q => q.settingId == currentSettingId).questionList.length}
                {questionIdList.length > 1 ? ' ' + t('QUESTIONS') : ' ' + t('QUESTION')} {t('SELECTED_B')}</span>
            </Typography>
          </>}
      </VmModal>
    </ContentLayout>
  )
});

export default ExamQuestionsPage;
